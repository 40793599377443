import { Button, Intent } from "@blueprintjs/core";
import cx from "classnames";

import styles from "./LoginDialog.module.scss";

type Props = {
    name?: string | null;
    ssoUrl?: string | null;
    onClick: (ssoUrl: string) => void;
};

export function ExternalIDP({ name, ssoUrl, onClick }: Props) {
    return (
        <Button className={cx(styles.authButton)} intent={Intent.SUCCESS} onClick={() => onClick(ssoUrl || "")}>
            Log in with <span style={{ fontWeight: "bold" }}>{name || ""}</span>
        </Button>
    );
}
