import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import React from "react";

interface AccessPendingDialogProps {
    isOpen: boolean;
    onClose(): void;
}

export const ACCESS_PENDING_DIALOG_TITLE = "Access Pending";

export const AccessPendingDialog: React.FC<AccessPendingDialogProps> = ({
    isOpen,
    onClose
}: AccessPendingDialogProps) => (
    <Dialog isOpen={isOpen} title={ACCESS_PENDING_DIALOG_TITLE} className="bp3-dark" isCloseButtonShown={false}>
        <div className={Classes.DIALOG_BODY}>
            You have insufficient permissions to view Lattice. Contact an administrator to grant you access in the Auth
            Console.
        </div>
        <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button intent={Intent.PRIMARY} onClick={onClose} text="OK" />
            </div>
        </div>
    </Dialog>
);
