import { client } from "./rpc/clients";

async function refreshToken() {
    try {
        await client.tokenClient.RefreshSessionToken({});
        return true;
    } catch (err) {
        return false;
    }
}

export const TokenAPI = {
    refreshToken
};
