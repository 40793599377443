import "./index.css";

import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { App } from "./app";
import { SessionRefreshView } from "./login/SessionRefresh";
import * as serviceWorker from "./serviceWorker";

// Disable type errors for react-router-dom, there's an issue between TS and the
// ReactNode component return type for functional components
// https://github.com/typescript-cheatsheets/react/issues/129#issuecomment-508412266
ReactDOM.render(
    <BrowserRouter basename={"/login"}>
        <div>
            <Switch>
                <Route path="/session-refresh" component={SessionRefreshView} />
                <Route path="/" component={App} />
            </Switch>
        </div>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
