import { Classes } from "@blueprintjs/core";
import cx from "classnames";
import React from "react";

import { Header } from "../components/Header";
import styles from "../main/MainView.module.scss";
import { SessionRefreshComponent } from "./SessionRefreshComponent";

export class SessionRefreshView extends React.Component {
    public render() {
        return (
            <div className={cx(Classes.DARK, styles.mainView)}>
                <Header />
                <SessionRefreshComponent />
            </div>
        );
    }
}
