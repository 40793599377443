import { Classes, Spinner } from "@blueprintjs/core";
import cx from "classnames";
import { useState } from "react";

import { AccessPendingDialog } from "../access-pending";
import { TokenAPI } from "../api/token-api";
import { Header } from "../components/Header";
import { Login } from "../login/LoginDialog";
import { RememberMeManager } from "../login/RememberMeManager";
import styles from "../main/MainView.module.scss";
import { getRelayState, isAccessPending } from "../route/route";
import { SystemUseDialog } from "../system-use";

function redirPath(path: string) {
    const link = document.createElement<"a">("a");
    link.href = path;
    link.rel = "noreferrer";
    document.body.appendChild(link);
    link.click();
}

export function MainView() {
    const [loading, setLoading] = useState(true);
    const [isAccessPendingDialogOpen, setAccessPendingDialogOpen] = useState(isAccessPending());
    const [isSystemUseDialogOpen, setSystemUseDialogOpen] = useState(!isAccessPending());
    const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
    const shouldAutoRefresh = RememberMeManager.get();
    if (shouldAutoRefresh && loading) {
        TokenAPI.refreshToken().then(isValidSession => {
            if (!isValidSession) {
                setLoading(false);
                return;
            }
            redirPath(getRelayState());
        });
        return (
            <div style={{ margin: "128px" }}>
                <Spinner size={150} />
            </div>
        );
    }
    const closeAccessPendingDialog = () => {
        setAccessPendingDialogOpen(false);
        setLoginDialogOpen(true);
    };
    const closeSystemUseDialog = () => {
        setSystemUseDialogOpen(false);
        if (!isAccessPendingDialogOpen) {
            setLoginDialogOpen(true);
        }
    };
    return (
        <div className={cx(Classes.DARK, styles.mainView)}>
            <Header />
            <AccessPendingDialog isOpen={isAccessPendingDialogOpen} onClose={closeAccessPendingDialog} />
            <SystemUseDialog isOpen={isSystemUseDialogOpen} onClose={closeSystemUseDialog} />
            <Login isOpen={isLoginDialogOpen} />
        </div>
    );
}
