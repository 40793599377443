import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

import { DispatchApi } from "../api/dispatch-api";

interface SystemUseDialogProps {
    isOpen: boolean;
    onClose(): void;
}

export const SYSTEM_USE_DIALOG_TITLE = "System Use Warning";

export const SystemUseDialog: React.FC<SystemUseDialogProps> = ({ isOpen, onClose }: SystemUseDialogProps) => {
    const [message, setMessage] = useState<string>("");
    const [initialized, setInitialized] = useState<boolean>(false);
    useEffect(() => {
        if (initialized) {
            return;
        }
        const abortController = new AbortController();
        DispatchApi.getSystemUseMessage()
            .then(msg => {
                if (!abortController.signal.aborted) {
                    return msg ? setMessage(msg) : onClose();
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialized(true);
                }
            });

        return () => abortController.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, onClose]);
    const msgInParagraphs: string[] = message?.split("\\n") || [];
    return (
        <Dialog isOpen={isOpen} title={SYSTEM_USE_DIALOG_TITLE} className="bp3-dark" isCloseButtonShown={false}>
            <div className={Classes.DIALOG_BODY}>
                {msgInParagraphs.map((paragraph: string, index: number) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button intent={Intent.PRIMARY} onClick={onClose} text="OK" />
                </div>
            </div>
        </Dialog>
    );
};
