import * as IIDP from "@anduril/andurilapis/anduril/auth/v2/idp";
import * as IInternalIDP from "@anduril/andurilapis/anduril/auth/v2/internal_idp";
import {
    Alignment,
    Button,
    Checkbox,
    Classes,
    Dialog,
    FocusStyleManager,
    FormGroup,
    InputGroup,
    Intent,
    Tooltip
} from "@blueprintjs/core";
import cx from "classnames";
import * as React from "react";

import type { DispatchIDP, DispatchSSOUrlResponse } from "../api/dispatch-api";
import { DispatchApi } from "../api/dispatch-api";
import { EulaText } from "../components/EulaText";
import { getRelayState } from "../route/route";
import { ExternalIDP } from "./ExternalIDP";
import styles from "./LoginDialog.module.scss";
import { PIV } from "./PIV";
import { RememberMeManager } from "./RememberMeManager";

enum Phase {
    Discovery,
    Selection,
    Password,
    MFA,
    NewPassword,
    NewPasswordMFA
}

interface IDiscovery {
    primaryIdp?: DispatchIDP | null;
}

interface ISelection {
    internal?: boolean;
    piv?: boolean;
    externalSAML?: IIDP.ExternalIDP[];
}

interface ILoginProps {
    isOpen: boolean;
}

interface ILoginState {
    phase: Phase;
    email: string;
    promptText: string;
    loading: boolean; // a flag to show a loading indicator while API calls are being made

    discovery: IDiscovery;

    selection?: ISelection;
    selectionError: string;

    showPasswordText: boolean;
    currentPassword: string;
    currentPasswordError: string;

    showNewPasswordText: boolean;
    newPassword: string;
    newPasswordError: string;

    rememberMeCheckbox: boolean;

    mfaToken: string;
    mfaTokenError: string;
}

const initialState = {
    phase: Phase.Discovery,
    email: "",
    promptText: "",
    loading: false,
    discovery: {},
    selectionError: "",

    showPasswordText: false,
    currentPassword: "",
    currentPasswordError: "",

    showNewPasswordText: false,
    newPassword: "",
    newPasswordError: "",

    rememberMeCheckbox: RememberMeManager.get(),

    mfaToken: "",
    mfaTokenError: ""
};

export const LOGIN_DIALOG_TITLE = "Log In";

export class Login extends React.Component<ILoginProps, ILoginState> {
    unmounted = false;
    constructor(props: ILoginProps) {
        super(props);
        FocusStyleManager.onlyShowFocusOnTabs();
        this.state = initialState;
    }

    private static internalIdpLogin(acsPath: string, samlResponse: string) {
        const form = document.createElement<"form">("form");
        form.method = "post";
        form.action = acsPath;

        const samlResponseInput = document.createElement<"input">("input");
        samlResponseInput.name = "SAMLResponse";
        samlResponseInput.value = samlResponse;
        form.appendChild(samlResponseInput);

        const relayStateInput = document.createElement<"input">("input");
        relayStateInput.name = "RelayState";

        relayStateInput.value = getRelayState();
        form.appendChild(relayStateInput);

        // Don't send referrer with request
        const meta = document.createElement<"meta">("meta");
        meta.name = "referrer";
        meta.content = "no-referrer";

        document.body.appendChild(form);
        document.head.appendChild(meta);
        form.submit();
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    async componentDidMount() {
        this.initializeDiscoveryPrompt();
    }

    public render() {
        // Upon login app's initial render, only display primary IdP + email form (Discovery phase)
        // If the user enters an email, display all valid options from getSSOUrl (enter Selection Phase)
        // From Selection, can either i) dispatch to external IdP or ii) transition to other phases (MFA, NewPassword)
        const { phase, selection } = this.state;
        const { isOpen } = this.props;
        return (
            <Dialog
                isOpen={isOpen}
                className={styles.loginDialog}
                title={LOGIN_DIALOG_TITLE}
                portalClassName={Classes.DARK}
                canOutsideClickClose={false}
                canEscapeKeyClose={false}
                isCloseButtonShown={phase !== Phase.Discovery}
                onClose={this.handleClose}
            >
                <div className={cx(Classes.DIALOG_BODY, styles.body)}>
                    {phase === Phase.Discovery && this.showDiscovery()}
                    {phase >= Phase.Selection && this.showSelection()}
                    {(phase !== Phase.Selection || selection?.internal) && this.showEmail()}
                    {(phase >= Phase.Password || selection?.internal) && this.showCurrentPassword()}
                    {phase >= Phase.NewPassword && this.showPasswordReset()}
                    {[Phase.MFA, Phase.NewPasswordMFA].includes(phase) && this.showMFA()}
                </div>
                {(phase !== Phase.Selection || selection?.internal) && (
                    <div className={cx(Classes.DIALOG_FOOTER, styles.footer)}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>{this.showEmailLoginButton()}</div>
                    </div>
                )}
                <div className={cx(Classes.DIALOG_FOOTER, styles.rememberMeFooter)}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>{this.showRememberMeCheckbox()}</div>
                </div>

                <EulaText />
            </Dialog>
        );
    }

    private handleClose = () => {
        this.setState(initialState);
        this.initializeDiscoveryPrompt();
    };

    private initializeDiscoveryPrompt = async () => {
        // Initialize discovery prompt to msg URL param & initialize primary IdP
        const discovery: IDiscovery = {};
        try {
            discovery.primaryIdp = await DispatchApi.getPrimaryIDP(getRelayState());
        } catch (e) {
            console.warn(e);
        }
        const errorMsg = new URLSearchParams(globalThis.location.search).get("msg") || "";
        if (!this.unmounted) {
            this.setState({ promptText: errorMsg, discovery });
        }
    };

    private triggerLoadingAndHandleSubmit = async () => {
        this.setState({ loading: true }, () => {
            this.handleSubmit().then(() => this.setState({ loading: false }));
        });
    };

    private handleSubmit = async () => {
        const { phase, selection, currentPassword, mfaToken, newPassword } = this.state;
        this.setState({
            promptText: "",
            selectionError: "",
            currentPasswordError: "",
            mfaTokenError: "",
            newPasswordError: ""
        });
        if (!this.validateForm()) {
            return;
        }
        if (phase === Phase.Discovery) {
            await this.handleDiscovery();
            return;
        } else if (phase < Phase.Password && !selection?.internal) {
            return;
        }
        const loginResult = await DispatchApi.loginPassword(this.state.email, currentPassword, mfaToken, newPassword);
        if (loginResult.success) {
            const { acsPath, samlResponse } = loginResult.success;
            if (acsPath && samlResponse) {
                Login.internalIdpLogin(acsPath, samlResponse);
            }
            return;
        }

        switch (loginResult.failure?.reason) {
            case IInternalIDP.LoginFailureReason.INVALID_EMAIL_PASSWORD:
                this.setState({ currentPasswordError: loginResult.failure?.message ?? "" });
                break;
            case IInternalIDP.LoginFailureReason.INVALID_MFA_TOKEN:
                this.setState({ mfaTokenError: loginResult.failure?.message ?? "" });
                break;
            case IInternalIDP.LoginFailureReason.MFA_TOKEN_REQUIRED_BUT_NOT_SPECIFIED:
                this.setState({ phase: phase === Phase.NewPassword ? Phase.NewPasswordMFA : Phase.MFA });
                break;
            case IInternalIDP.LoginFailureReason.PASSWORD_RESET_REQUIRED:
                this.setState({ phase: Phase.NewPassword });
                break;
            case IInternalIDP.LoginFailureReason.NEW_PASSWORD_FAILS_CONSTRAINT:
                this.setState({ newPasswordError: loginResult.failure?.message ?? "" });
                break;
            case IInternalIDP.LoginFailureReason.USER_DISABLED:
            default:
                this.setState({ currentPasswordError: loginResult.failure?.message ?? "" });
                break;
        }
    };

    private showDiscovery() {
        const { discovery, selectionError } = this.state;
        const e = discovery.primaryIdp?.external;
        const primaryIdp = discovery.primaryIdp;
        const hasFederatedAuth = primaryIdp && !primaryIdp.internal;
        return (
            <div>
                {(primaryIdp?.external || primaryIdp?.piv) && (
                    <div className={cx(styles.authButtonContainer)}>
                        {primaryIdp?.external && (
                            <ExternalIDP name={e?.name} ssoUrl={e?.ssoUrl} onClick={this.handleSelectSAML} />
                        )}
                        {primaryIdp?.piv && !!discovery.primaryIdp?.piv && <PIV onClick={this.handleSelectCACPIV} />}
                    </div>
                )}
                {hasFederatedAuth && (
                    <>
                        <FormGroup className={cx(styles.label)} helperText={selectionError} intent={Intent.DANGER} />
                        <div className={cx(styles.authDividerContainer)}> or </div>
                    </>
                )}
            </div>
        );
    }

    private showEmail() {
        const { promptText } = this.state;
        return (
            <FormGroup
                label="Email"
                labelFor="text-input"
                className={cx(styles.label)}
                helperText={promptText}
                intent={Intent.DANGER}
            >
                <InputGroup
                    className={Classes.FILL}
                    type={"text"}
                    dir="auto"
                    value={this.state.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value })}
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.keyCode === 13) {
                            this.triggerLoadingAndHandleSubmit();
                        }
                    }}
                />
            </FormGroup>
        );
    }

    private async handleDiscovery() {
        const { email } = this.state;
        let idpSSOResults: DispatchSSOUrlResponse = { invalidEmail: undefined, validDomain: undefined };
        try {
            idpSSOResults = await DispatchApi.getSSOUrl(email.trim(), getRelayState());
        } catch (e) {
            idpSSOResults.invalidEmail = IIDP.GetSSOURLResponse_InvalidEmail.fromPartial({});
        }
        if (idpSSOResults.invalidEmail) {
            const invalidEmailPrompt =
                idpSSOResults.invalidEmail?.reason !== ""
                    ? "Please enter a valid email address: " + idpSSOResults.invalidEmail?.reason
                    : "Login currently unavailable, please try again";
            this.setState({ promptText: invalidEmailPrompt });
            return;
        }
        const validIdps = idpSSOResults.validDomain?.idps ?? [];
        if (!validIdps?.length) {
            console.warn("Unexpected state: no valid ways to authenticate for the provided email");
            return;
        } else if (validIdps?.length === 1 && validIdps[0].internal) {
            // Transition to internal-only Password phase
            this.setState({ phase: Phase.Password });
            return;
        }
        // Transition to Selection phase
        this.setSelectionState(idpSSOResults);
    }

    private setSelectionState(authOpts: DispatchSSOUrlResponse) {
        const selection: ISelection = {
            externalSAML: []
        };
        for (let idp of authOpts?.validDomain?.idps || []) {
            if (idp.piv) {
                selection.piv = true;
            } else if (idp.internal) {
                selection.internal = true;
            } else if (idp.external) {
                selection.externalSAML?.push(idp.external);
            }
        }
        this.setState({ selection });
        this.setState({ phase: Phase.Selection });
    }

    private showSelection() {
        const { selection, selectionError } = this.state;
        if (!selection) {
            return;
        }
        const federatedAuthButtons = (selection.externalSAML || []).map(e => (
            <ExternalIDP key={e?.name} name={e?.name} ssoUrl={e?.ssoUrl} onClick={this.handleSelectSAML} />
        ));
        const shouldDisplayDivider = (selection.piv || selection.externalSAML?.length) && selection.internal;
        return (
            <div>
                <div className={cx(styles.authButtonContainer)}>
                    {federatedAuthButtons}
                    {!!selection.piv && <PIV onClick={this.handleSelectCACPIV} />}
                </div>
                <FormGroup className={cx(styles.label)} helperText={selectionError} intent={Intent.DANGER} />
                {shouldDisplayDivider && <div className={cx(styles.authDividerContainer)}> or </div>}
            </div>
        );
    }

    private showCurrentPassword(): JSX.Element {
        const { showPasswordText, currentPassword, currentPasswordError } = this.state;
        return (
            <FormGroup
                label="Password"
                className={cx(styles.loginDialogElement)}
                labelFor="text-input"
                helperText={currentPasswordError}
                intent={Intent.DANGER}
            >
                <InputGroup
                    autoFocus={true}
                    className={Classes.FILL}
                    type={showPasswordText ? "text" : "password"}
                    dir="auto"
                    value={currentPassword}
                    rightElement={this.showPasswordButton()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({
                            currentPassword: e.target.value
                        })
                    }
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.keyCode === 13) {
                            this.triggerLoadingAndHandleSubmit();
                        }
                    }}
                />
            </FormGroup>
        );
    }

    private showPasswordButton(): JSX.Element {
        const { showPasswordText } = this.state;
        return (
            <Tooltip content={`${showPasswordText ? "Hide" : "Show"} Password`}>
                <Button
                    icon={showPasswordText ? "unlock" : "lock"}
                    intent={Intent.WARNING}
                    minimal={true}
                    onClick={() => this.setState({ showPasswordText: !showPasswordText })}
                />
            </Tooltip>
        );
    }

    private showPasswordReset(): JSX.Element {
        const { showNewPasswordText, newPassword, newPasswordError } = this.state;
        return (
            <FormGroup
                label="New Password"
                className={cx(styles.loginDialogElement)}
                labelFor="text-input"
                // If the MFA input is shown, then the error must be with it, so don't show it here
                helperText={newPasswordError}
                intent={Intent.DANGER}
            >
                <InputGroup
                    autoFocus={true}
                    className={Classes.FILL}
                    type={showNewPasswordText ? "text" : "password"}
                    dir="auto"
                    value={newPassword}
                    rightElement={this.showNewPasswordButton()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({
                            newPassword: e.target.value
                        })
                    }
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.keyCode === 13) {
                            this.triggerLoadingAndHandleSubmit();
                        }
                    }}
                />
            </FormGroup>
        );
    }

    private showNewPasswordButton(): JSX.Element {
        const { showNewPasswordText } = this.state;
        return (
            <Tooltip content={`${showNewPasswordText ? "Hide" : "Show"} Password`}>
                <Button
                    icon={showNewPasswordText ? "unlock" : "lock"}
                    intent={Intent.WARNING}
                    minimal={true}
                    onClick={() => this.setState({ showNewPasswordText: !showNewPasswordText })}
                />
            </Tooltip>
        );
    }

    private showMFA(): JSX.Element {
        return (
            <FormGroup
                label="MFA"
                className={cx(styles.loginDialogElement)}
                labelFor="text-input"
                helperText={this.state.mfaTokenError}
                intent={Intent.DANGER}
            >
                <InputGroup
                    autoFocus={true}
                    className={Classes.FILL}
                    type={"text"}
                    dir="auto"
                    value={this.state.mfaToken}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({
                            mfaToken: e.target.value
                        })
                    }
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.keyCode === 13) {
                            this.triggerLoadingAndHandleSubmit();
                        }
                    }}
                />
            </FormGroup>
        );
    }

    private showRememberMeCheckbox = () => (
        <Checkbox
            checked={this.state.rememberMeCheckbox}
            label="Remember me on this browser"
            alignIndicator={Alignment.RIGHT}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const rememberMeVal = e.target.checked;
                this.setState({
                    rememberMeCheckbox: RememberMeManager.set(rememberMeVal)
                });
            }}
        />
    );

    private showEmailLoginButton = () => {
        const { phase } = this.state;
        return (
            <Button
                className={cx(styles.authButton)}
                intent={Intent.PRIMARY}
                onClick={this.triggerLoadingAndHandleSubmit}
                loading={this.state.loading}
            >
                {phase === Phase.Discovery && "Continue with "}
                <span style={{ fontWeight: "bold" }}> {(phase === Phase.Discovery && "Email") || "Log in"}</span>
            </Button>
        );
    };

    private handleSelectSAML = (ssourl: string) => {
        this.setState({
            promptText: "",
            selectionError: "",
            currentPasswordError: "",
            mfaTokenError: "",
            newPasswordError: ""
        });
        globalThis.location.href = ssourl;
    };

    private handleSelectCACPIV = async () => {
        this.setState({
            promptText: "",
            selectionError: "",
            currentPasswordError: "",
            mfaTokenError: "",
            newPasswordError: ""
        });
        const resp = await DispatchApi.loginSignedCert();
        if (resp.failure) {
            this.setState({ selectionError: resp.failure?.message || "PIV login failed" });
            return;
        }
        const { acsPath, samlResponse } = resp.success || {};
        if (acsPath && samlResponse) {
            Login.internalIdpLogin(acsPath, samlResponse);
        }
    };

    private validateForm(): boolean {
        if (!this.state.email.includes("@")) {
            this.setState({ promptText: "Please enter a valid email address" });
            return false;
        }
        return true;
    }
}
