import { Button, Classes, Dialog } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { IconNames } from "@blueprintjs/icons";
import cx from "classnames";
import React, { useEffect, useState } from "react";

import styles from "./LoginDialog.module.scss";

export const SessionRefreshComponent: React.FC = () => {
    let [secondsLeft, setSecondsLeftVal] = useState(5);

    useEffect(() => {
        const interval = setInterval(() => {
            const nextSeconds = Math.max(0, secondsLeft - 1);
            if (nextSeconds === 0) {
                globalThis.close();
            }
            setSecondsLeftVal(nextSeconds);
        }, 1000);
        return () => clearInterval(interval);
    }, [secondsLeft]);

    const onClose = () => {
        globalThis.close();
    };

    return (
        <Dialog
            className={styles.loginDialog}
            title={"Login Success"}
            portalClassName={Classes.DARK}
            icon={IconNames.TICK_CIRCLE}
            isOpen={true}
            isCloseButtonShown={false}
        >
            <div className={cx(Classes.DIALOG_BODY, styles.body)}>
                Your session has successfully been extended.
                <br />
                <br />
                You will be returned to your work automatically in {secondsLeft} seconds.
            </div>
            <div className={cx(Classes.DIALOG_FOOTER, styles.footer)}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Close" onClick={onClose} intent={Intent.PRIMARY} />
                </div>
            </div>
        </Dialog>
    );
};
