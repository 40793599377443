const rememberMeKey = "login.remember_me";

function getRememberMeState() {
    try {
        const shouldRememberMe = globalThis.localStorage.getItem(rememberMeKey) || "";
        return JSON.parse(shouldRememberMe) || false;
    } catch (err) {
        setRememberMeState(false);
        return false;
    }
}
function setRememberMeState(rememberMe: boolean) {
    globalThis.localStorage.setItem(rememberMeKey, rememberMe.toString());
    return getRememberMeState();
}

export const RememberMeManager = {
    get: getRememberMeState,
    set: setRememberMeState
};
