import { parse } from "query-string";

const REFERRER_PARAM = "ref";
const ACCESS_PENDING_PARAM = "accessPending";

export function getRelayState(): string {
    const curUrl = new URL(globalThis.location.href);
    const ref = curUrl.searchParams.get(REFERRER_PARAM) || "";
    const path = ref.startsWith("/") ? ref.slice(1) : ref;
    return `${curUrl.origin}/${path}`;
}

export function isAccessPending(): boolean {
    const queryParams = parse(globalThis.location.search, { parseBooleans: true });
    const accessPending = queryParams[ACCESS_PENDING_PARAM];
    return typeof accessPending === "boolean" ? accessPending : false;
}
