import EULA_PDF from "../../public/files/Lattice_EULA.pdf";
import styles from "./EulaText.module.scss";

export const EulaText = () => (
    <div className={styles.container}>
        {"Anduril Copyright © All Rights Reserved."}
        <a className={styles.link} href={EULA_PDF}>
            {"End-User License Agreement"}
        </a>
    </div>
);
