import * as IIDP from "@anduril/andurilapis/anduril/auth/v2/idp";
import * as IInternalIDP from "@anduril/andurilapis/anduril/auth/v2/internal_idp";
import * as IPivIDP from "@anduril/andurilapis/anduril/auth/v2/piv_idp";
import * as ISystemUse from "@anduril/andurilapis/anduril/auth/v2/system_use";
import * as ITokens from "@anduril/andurilapis/anduril/auth/v2/tokens";
import { grpc } from "@improbable-eng/grpc-web";
import { NodeHttpTransport } from "@improbable-eng/grpc-web-node-http-transport";
interface IRpcTransportOptions {
    bearerToken?: string;
    subdomain?: string;
}

class Clients {
    metadata: grpc.Metadata;
    idpsClient: IIDP.IdpsClientImpl;
    internalIDPClient: IInternalIDP.InternalIdpClientImpl;
    pivIdpClient: IPivIDP.PivIdpClientImpl;
    tokenClient: ITokens.TokensClientImpl;
    systemUseClientImpl: ISystemUse.SystemUseClientImpl;
    private subdomain?: string;
    private bearerToken?: string;

    constructor(transportOptions: IRpcTransportOptions) {
        this.bearerToken = transportOptions.bearerToken;
        this.subdomain = transportOptions.subdomain ? transportOptions.subdomain + "." : "";
        grpc.setDefaultTransport(NodeHttpTransport());
        this.metadata = new grpc.Metadata();
        if (this.bearerToken !== undefined) {
            this.metadata.set("authorization", `Bearer ${this.bearerToken}`);
        }
        this.metadata.set("content-type", "application/grpc-web");
        this.metadata.set("referrer-policy", "no-referrer-when-downgrade");

        this.systemUseClientImpl = new ISystemUse.SystemUseClientImpl(
            new ISystemUse.GrpcWebImpl(this.getHost(), { metadata: this.metadata })
        );
        this.tokenClient = new ITokens.TokensClientImpl(
            new ITokens.GrpcWebImpl(this.getHost(), { metadata: this.metadata })
        );
        this.idpsClient = new IIDP.IdpsClientImpl(new IIDP.GrpcWebImpl(this.getHost(), { metadata: this.metadata }));
        this.internalIDPClient = new IInternalIDP.InternalIdpClientImpl(
            new IInternalIDP.GrpcWebImpl(this.getHost(), { metadata: this.metadata })
        );
        this.pivIdpClient = new IPivIDP.PivIdpClientImpl(
            new IPivIDP.GrpcWebImpl(this.getHost("piv"), { metadata: this.metadata })
        );
    }

    setBearerToken(bearerToken: string): void {
        this.bearerToken = bearerToken;
        this.metadata.set("authorization", `Bearer ${this.bearerToken}`);
    }

    getBearerToken(): string | undefined {
        return this.bearerToken;
    }

    getHost(subdomain?: string) {
        subdomain = subdomain ? subdomain + "." : undefined;
        return globalThis.location.protocol + "//" + (subdomain || this.subdomain) + globalThis.location.host;
    }
}

export const client = new Clients({});
