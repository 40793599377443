import { Classes } from "@blueprintjs/core";
import cx from "classnames";
import React from "react";

import AndurilLogo from "../assets/anduril.svg";
import styles from "./Header.module.scss";

export class Header extends React.PureComponent {
    public render() {
        return (
            <div className={cx(Classes.ELEVATION_2, styles.header)}>
                <img src={AndurilLogo} alt="Anduril logo" className={styles.logo} />
                <div className={styles.title}>Lattice</div>
            </div>
        );
    }
}
