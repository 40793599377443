import { Button, Intent } from "@blueprintjs/core";
import cx from "classnames";

import styles from "./LoginDialog.module.scss";

export function PIV({ onClick }: { onClick: () => void }) {
    return (
        <Button className={cx(styles.authButton)} intent={Intent.SUCCESS} onClick={onClick}>
            Log in with <span style={{ fontWeight: "bold" }}>PIV/CAC</span>
        </Button>
    );
}
