import type { LoginPasswordRequest } from "@anduril/andurilapis/anduril/auth/v2/internal_idp";
import type { GetSystemUseMessageResponse } from "@anduril/andurilapis/anduril/auth/v2/system_use";

import { client } from "./rpc/clients";

//https://github.com/piotrwitek/utility-types#promisetype
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PromiseType<T extends Promise<any>> = T extends Promise<infer U> ? U : never;

export class DispatchApi {
    public static async getSystemUseMessage() {
        let resp: GetSystemUseMessageResponse;
        try {
            resp = await client.systemUseClientImpl.GetSystemUseMessage({});
            return resp.message;
        } catch (e) {
            console.info("System use message not set");
        }
    }

    public static async getSSOUrl(email: string, redirectUrl: string) {
        const { invalidEmail, validDomain } = await client.idpsClient.GetSSOURL({
            email,
            redirectUrl
        });

        const mappedIdps =
            validDomain?.idps?.map(idp => ({
                external: idp?.external,
                internal: idp?.internal,
                piv: idp?.piv
            })) || [];

        return {
            invalidEmail,
            validDomain: validDomain
                ? {
                      idps: mappedIdps
                  }
                : undefined
        };
    }

    public static async getPrimaryIDP(redirectUrl: string) {
        const { idp } = await client.idpsClient.GetPrimaryIDP({ redirectUrl });
        return idp;
    }

    public static async loginPassword(
        email: string,
        currentPassword: string,
        mfaToken: string = "",
        newPassword: string = ""
    ) {
        let request: LoginPasswordRequest = {
            email,
            mfaToken,
            password: currentPassword,
            newPassword: (newPassword && newPassword.trim()) || ""
        };
        return client.internalIDPClient.LoginPassword(request);
    }

    public static async loginSignedCert() {
        return client.pivIdpClient.LoginSignedCert({});
    }
}

export type DispatchIDP = PromiseType<ReturnType<typeof DispatchApi["getPrimaryIDP"]>>;
export type DispatchSSOUrlResponse = Partial<PromiseType<ReturnType<typeof DispatchApi["getSSOUrl"]>>>;
